* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html,
body,
address,
blockquote,
div,
dl,
form,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
p,
pre,
table,
ul,
dd,
dt,
li,
tbody,
td,
tfoot,
th,
thead,
tr,
button,
del,
ins,
map,
object,
a,
abbr,
acronym,
b,
bdo,
big,
br,
cite,
code,
dfn,
em,
i,
img,
kbd,
q,
samp,
small,
span,
strong,
sub,
sup,
tt,
var,
legend,
fieldset,
p,
aside,
header,
footer,
section,
article {
    border: medium none;
    margin: 0;
    padding: 0;
}

:focus {
    outline: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 500;
}

h1 {
    font-family: 'Krona One', sans-serif;
    font-size: 55px;
    font-weight: 700;
}

h2 {
    font-family: 'Inter', sans-serif;
    font-size: 45px;
    font-weight: 600;
}

h3 {
    font-family: 'Inter', sans-serif;
    font-size: 24px;
}

p {
    font-family: 'Inter', sans-serif;
}

h4 {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
}

img,
fieldset {
    border: 0 none;
}

a {
    font-family: 'Inter', sans-serif;
    border: none;
    outline: none;
}

input[type="submit"],
select {
    -webkit-appearance: none;
}

img {

    border: none;
    height: auto;
    max-width: 100%;
    outline: none;
    vertical-align: middle;
}

iframe {
    vertical-align: middle;
}

a:hover {
    font-family: 'Inter', sans-serif;
    text-decoration: none;
    color: #002395;
}

.clear {
    clear: both;
}

ul::after {
    font-family: 'Inter', sans-serif;
    clear: both;
    content: "";
    display: block;
}

ul {
    font-family: 'Inter', sans-serif;
    list-style: none;
}

body {
    font-family: 'Inter', sans-serif;
    margin: 0;
    padding: 0;
    font-size: 16px;
    line-height: 25px;
    color: #343B52;
    -webkit-font-smoothing: antialiased;
}

header .navbar-light .navbar-nav .nav-link {
    color: rgb(0 0 0);
    margin-left: 20px;
}

.join-us-now-btn,
.join-us-now-btn:hover {
    border-radius: 10px;
    background: var(--mixup, linear-gradient(268deg, #FFCBD7 2.72%, #FFE4BE 98.87%));
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.25);
    font-weight: 600;
    padding: 10px 15px !important;
    cursor: pointer !important;
    color: #000 !important;
    border: navajowhite;
    width: fit-content;
}

.hero-bannner-section {
    background: url("../images/hero-banner.png") no-repeat;
    /* height: 70vh; */
    background-position: left bottom;
    display: flex;
    align-items: center;
    padding: 40px 0 0 0;
}

.choose-us-section {
    background: url("../images/choose-banner.png") no-repeat;
    background-position: left bottom;
    background-size: cover;
}

.testimonial-box {
    background: var(--mixup, linear-gradient(268deg, #FFCBD7 2.72%, #FFE4BE 98.87%));
    padding: 72px 13px 0 13px;
    border-radius: 50px;
    position: relative;
}

.login-section {
    background: url("../images/login-bg.png") no-repeat;
    background-size: cover;
    padding: 102px 0;
    background-position: center;
    width: 100%;
}

.hero-left-content h1 {
    font-size: 32px;
    text-transform: uppercase;
    color: #000;
    margin-bottom: 12px;
}

.hero-left-content h3 {
    text-transform: uppercase;
    letter-spacing: 2.54px;
    color: #000;
    font-size: 19px;
    letter-spacing: 0px;
}

p {
    font-size: 16px;
    margin-top: 9px;
    margin-bottom: 25px;
    color: #00000087;
}

.subheading {
    color: #00A850;
    text-transform: uppercase;
    font-family: 'Krona One', sans-serif;
    font-size: 37px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}

.under-subheading {
    color: #000;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 4px;
    text-transform: uppercase;
}

.common-padding {
    padding: 48px 0;
}

.choose-under-content img {
    margin-top: 15px;
}

.text-black {
    color: #000;
}

.service-section {
    background: var(--mixup, linear-gradient(268deg, #FFCBD7 2.72%, #FFE4BE 98.87%));
}

.service-inner {
    border-radius: 40px 0px 40px 40px;
    border: 1px solid #000;
    box-shadow: 12px 12px 7px 0px rgb(0 0 0 / 26%);
    padding: 43px;
    color: #000;
    text-align: center;
}

.service-inner h2 {
    font-family: "Krona One";
    font-size: 58px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.service-inner h5 {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

.box-shadow-none {
    box-shadow: none;
}

.service-inner.right {
    border-radius: 0 40px 40px 40px !important;
}

.cultivatorss-content {
    position: relative;
}

.cultivatorss-content img {
    position: absolute;
    top: -40px;
}

.cultivator-content {
    padding: 62px 90px;
}

.star-box i {
    color: #FFC107;
    margin-top: 12px;
    margin-right: 7px;
}

.cultivator-content h6 {
    color: #000;
    font-size: 22px;
    font-weight: 700;
}

.cultivator-content p {
    color: #000;
    font-weight: 500;
}

.cultivatorss-img {
    margin-top: 64px;
    margin-left: 9px;
}

.apple-btn {
    display: inline-flex;
    padding: 14px;
    border-radius: 10px;
    border-radius: 5px;
    background: #000;
    box-shadow: 0px 0px 30px 6px rgba(0, 0, 0, 0.06);
    color: #fff;
    text-decoration: none;
    padding: 13px 29px;
}

.play-store-box a,
.play-store-box a:hover {
    margin-right: 17px;
    color: #fff;
}

.apple-btn img {
    margin-right: 14px;
}

.apple-btn.play-btn {
    border: 1px solid #000;
    background: transparent;
    color: #000 !important;
}

.newslater-box {
    border-radius: 70px;
    background: var(--mixup, linear-gradient(268deg, #FFCBD7 2.72%, #FFE4BE 98.87%));
    padding: 112px;
}

.form-newslater .form-control {
    border-radius: 12px;
    border: 1px solid #D2D2D2;
    background: #FFF;
    box-shadow: 0px 24px 54px 0px rgba(0, 0, 0, 0.07);
    padding: 14px;
    padding-right: 57px;
}

.form-newslater {
    display: flex;
    padding: 7px 112px 0;
}

.green-btn {
    border-radius: 12px;
    background: var(--green-color, #00A850);
    box-shadow: 0px 24px 54px 0px rgba(0, 0, 0, 0.07);
    border: navajowhite;
    padding: 10px 42px;
    right: 31px;
    position: relative;
    text-transform: uppercase;
    cursor: pointer;
}
.red-btn {
    border-radius: 12px;
    background:#dc3545;
    box-shadow: 0px 24px 54px 0px rgba(0, 0, 0, 0.07);
    border: navajowhite;
    padding: 10px 42px;
    /* right: 31px; */
    position: relative;
    text-transform: uppercase;
    cursor: pointer;
}
.footer-contents ul li a {
    color: #000;
    text-decoration: none;
    margin: 0 22px;
}

.footer-contents ul {
    display: flex;
    justify-content: center;
}

.footer-body {
    background: var(--mixup, linear-gradient(268deg, #FFCBD7 2.72%, #FFE4BE 98.87%));
}

.copy-right-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.social-media ul {
    display: flex;
}

.social-media ul li {
    margin-left: 13px;
}

.copy-right-section p {
    color: #000;
}

.cultivatorss-section .swiper-button-prev,
.cultivatorss-section .swiper-button-next {
    bottom: 12px;
    top: unset;
}

.cultivatorss-section .swiper-button-prev {
    left: unset !important;
    right: 63px !important;
}

.cultivatorss-section .swiper-button-next,
.cultivatorss-section .swiper-button-prev {
    background: #00a850;
    width: 42px;
    height: 42px;
    border-radius: 32px;
    color: #fff;
}

.cultivatorss-section .swiper-button-prev:after,
.cultivatorss-section .swiper-button-next:after {
    font-size: 21px;
}

.marketplace-section {
    padding: 48px 0 0 0;
}

.login-form-box {
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 5px 15px 0px rgba(107, 203, 252, 0.20);
    padding: 69px;
}

.login-header {
    text-align: center;
}

.login-header h2 {
    font-size: 35px;
    color: #00a850;
}

.login-body .form-control,
.login-body .form-select {
    border-radius: 10px;
    border: 1px solid #E3E3E3;
    /* background: #FFF; */
    padding: 17px;
    color: #112F49;
    font-weight: 500;
    /* background: transparent !important; */
}

.login-body .form-control::placeholder,
.login-body .form-select::placeholder {
    color: #00000087;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

.form-control:focus,
.form-select:focus {
    box-shadow: none !important;
}

.forgot-password-txt,
.forgot-password-txt:hover {
    color: #748CA1;
    display: block;
    text-align: right;
    text-decoration: none;
}

.login-body .green-btn,
.login-body .green-btn:hover {
    border-radius: 42px;
    width: 100%;
    right: 0;
    margin-top: 45px;
    text-transform: unset;
    font-size: 18px;
    background-color: #00a850;
}

.login-body p {
    color: #748CA1;
    font-size: 18px;
    font-weight: 600;
    margin-top: 31px;
    text-align: center;
}

.login-body p a {
    color: #00a850;
    font-weight: 800;
    font-size: 20px;
}

.login-body .form-label {
    color: #112F49;
    opacity: 1;
    font-size: 18px;
    font-weight: 700;
}

.eye-icon {
    position: absolute;
    top: 56px;
    right: 18px;
    cursor: pointer;
}

.testimonial-box:after {
    content: "";
    position: absolute;
    width: 12px;
    height: 142px;
    top: -40px;
    width: 87%;
    left: 82px;
    z-index: -1;
    border: 7px solid #FFDCC6;
    border-radius: 42px;
}

.cultivatorss-section .subheading-section {
    margin-bottom: 72px !important;
}

header .navbar-light .navbar-toggler-icon {
    background-image: url("../images/menu.svg");
    width: 42px;
    height: 42px;
}

.dashboard-card {
    background-image: url("../images/card-1.svg");
    background-repeat: no-repeat;
    padding: 32px 18px;
    background-position: center;
    background-size: cover;
    border-radius: 17px;
    height: 100%;
}

.dashboard-card.dashboard-card2 {
    background-image: url("../images/card-2.svg");
}

.dashboard-card.dashboard-card3 {
    background-image: url("../images/card-3.svg");
}

.marketplace-content {
    background-image: url("../images/marketplace-bg.png");
}

header .navbar-light .navbar-toggler {
    color: unset;
    border-color: unset;
    border: 0;
}

header .navbar-toggler:focus {
    box-shadow: none !important;
}

.height-footer {
    min-height: calc(100vh - 366px);
}

.create-profile-section .login-header {
    margin-bottom: 32px;
}

.profile-upload img {
    width: 122px !important;
    border: 1px solid #e3e3e3;
    height: 122px;
    border-radius: 72px;
    cursor: pointer;
    object-fit: cover;
}

.price-text {
    width: 142px;
}

.profile-upload .form-control {
    position: absolute;
    width: 122px;
    height: 122px;
    left: 44%;
    opacity: 0;
}

.profile-upload p {
    margin-top: 14px !important;
    margin-bottom: 31px !important;
}

.file-upload {
    position: absolute;
    top: 49px;
    width: 37px !important;
    right: 14px;
    cursor: pointer;
    background: #fff;
    padding: 4px;
}

.action-btn .green-btn,
.action-btn .green-btn:hover {
    width: 30%;
}

.modal-success .modal-content {
    border-radius: 32px;
    padding: 0 15px;
}

.modal-success-body h3 {
    color: #000;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    margin-top: 13px;
}

.modal-success .green-btn,
.modal-success .green-btn:hover {
    right: 0;
    border-radius: 42px;
    background-color: #00a850 !important;
}

.common-tabs .nav-tabs .nav-link {
    color: #000;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 4px 10px 0px rgba(175, 137, 255, 0.15);
    border: 0;
    margin-left: 17px;
    color: #a7b7dd;
    margin-bottom: 7px;
}

.common-tabs .nav-tabs .nav-link.active {
    border-radius: 8px;
    background: #00A850;
    box-shadow: 0px 4px 10px 0px rgba(175, 137, 255, 0.15);
    color: #fff;
}

.common-tabs .nav-tabs .nav-link.active img {
    filter: brightness(0) invert(1);
}

.common-tabs .nav-tabs {
    border: 0;
    justify-content: end;
}

.common-tabs .nav-tabs .nav-link img {
    margin-right: 11px;
}

.subheading-under {
    color: #112F49;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}

.search-box .form-control {
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.10) inset;
    padding-left: 42px;
    padding-right: 30px;
    padding-top: 8px;
    padding-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.under-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.search-img {
    position: absolute;
    top: 10px;
    left: 12px;
}

.search-box .form-control {
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.10) inset;
    padding-left: 42px;
    padding-right: 30px;
}

.under-heading .green-btn,
.under-heading .green-btn:hover {
    right: 0;
    text-transform: unset;
    font-size: 14px;
    background-color: #00a850 !important;
}

.form-check-input {
    cursor: pointer;
}

.common-under-padding {
    padding: 28px 0;
}

.table-data table th {
    color: #112F49;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    padding: 14px 17px;
    background: #F4F4F4;
}

.table-data table th:first-child,
.table-data table td:first-child {
    padding: 14px 14px;
}

.table-data table thead {
    background: #F4F4F4;
}

.table-data table td {
    font-size: 12px;
    vertical-align: middle;
    color: #292929;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 203.9%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.10);
    padding: 20px 17px;
}

.description-txt {
    overflow: hidden !important;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal;
    width: 162px;
    font-size: 12px;
    color: #292929;
    line-height: 17px;
    margin: 0;
}

.action-data-box a {
    margin: 0 10px 0 0;
}

.form-check-input:checked {
    background-color: #00a850;
    border-color: #00a850;
}

.form-check-input:focus {
    box-shadow: unset !important;
}

.table-data {
    margin-top: 7px;
}

.project-managent-section.common-padding {
    padding: 27px 0;
}

.dashboard-card h5 {
    color: rgba(255, 255, 255, 0.75);
    font-size: 15.95px;
    font-style: normal;
    font-weight: 700;
    line-height: 23.926px;
    letter-spacing: 0.08px;
}

.dashboard-txt {
    color: var(--Neutral-White, #FFF);
    font-size: 27.913px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.279px;
}

.span-dashboard {
    border-radius: 3.988px;
    background: rgba(255, 255, 255, 0.15);
    font-size: 15px;
    padding: 5px 12px;
}

.dashboard-card img {
    margin-bottom: 12px;
}

.order-section {
    margin-top: 23px;
}

.latest-order-txt {
    color: #676767;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 131.9%;
}

.delivered {
    width: 8px;
    height: 8px;
    display: inline-block;
    background: #4CB64C;
    margin-right: 9px;
    border-radius: 23px;
}

.delivered.cancel {
    background: red;
}

.delivered.pending {
    background: var(--mixup, linear-gradient(268deg, #FFCBD7 2.72%, #FFE4BE 98.87%));
}

.green-btn.radius-btn {
    border-radius: 42px;
    text-transform: capitalize;
    padding: 13px 92px;
    right: 0;
    font-weight: 700;
}

.no-feedback {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 12px;
}

.no-feedback span {
    display: inline-block;
    margin-top: 12px;
}

.action-btn-add {
    text-align: right;
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;
}

.product-img-box img {
    width: 142px !important;
    height: 142px;
    border-radius: 22px;
    margin-right: 20px;
    object-fit: cover;
    border: 1px solid #748ca140;
}

.outer-product-img {
    display: flex;
}

.green-btn.radius-btn.small-btn {
    padding: 9px 42px;
}

.login-body .css-1ypvvfw-control,
.login-body .css-g8u12a-control {
    border-radius: 10px;
    border: 1px solid #D9D9D9;
    background: #FFF;
    padding: 4px;
}

.login-body .css-d7l1ni-option {
    background-color: #00A850;
    color: #fff;
    font-size: 14px;
}

.login-body .css-1l3uons-MenuList {
    max-height: 100%;
    padding-top: 0;
    padding-bottom: 0;
}

.login-body .css-1nmdiq5-menu {
    box-shadow: 0 !important;
    border: 0.5px solid #00A850;
    border-radius: 22px;
}

.login-body .css-10wo9uf-option {
    border-bottom: 1px solid #00a850;
    font-size: 14px;
}

.login-body .css-d7l1ni-option:first-child {
    border-radius: 14px 14px 0 0;
}

.login-body .css-d7l1ni-option:last-child {
    border-radius: 0 0 14px 14px;
}

.login-body .css-10wo9uf-option:last-child {
    border-bottom: 0 !important;
}

.common-under-padding {
    padding: 32px 0;
}

.login-body .css-16dobtq-MenuList {
    max-height: 100%;
}

/* Chrome, Safari, Edge, Opera hide number field arrow */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.product-header-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
}

.green-btn.radius-btn.cancel-btn-outline {
    border: 2px solid #FF4848;
    background: transparent;
    color: #FF4848;
    margin-right: 12px;
    padding: 13px 67px;
}

.stock-shadow {
    border-radius: 28px;
    background: #FFF;
    box-shadow: 0px 4px 33px 10px rgba(0, 0, 0, 0.08);
    padding: 22px 44px;
}

.product-name {
    background: #0000000f;
    padding: 14px;
    text-align: left !important;
    border-radius: 11px;
    margin-top: 0 !important;
    margin-right: 152px;
}

.stock-shadow .login-body .form-control,
.stock-shadow .login-body .form-select {
    padding: 10px;
}
.alert-heading:after {
    content: ":";
    position: absolute;
}
/* .fade.alert.alert-warning.alert-dismissible p:before {
    content: ":";
    position: absolute;
    color: #fff3cd;
    width: 8px;
    height: 26px;
    background: #fff3cd;
} */
.status-bar {
    display: flex;
    flex-wrap: wrap;
}

.status-bar .form-select {
    margin-left: 13px;
    border-radius: 10px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.10) inset;
    padding-left: 14px;
    width: -moz-fit-content;
    width: fit-content;
    /* width: 200px; */
    height: 43px;
}

span.order-img {
    display: inline-block;
    margin-right: 10px;
    width: 60px;
    height: 60px;
    border-radius: 11px;
}

.dot-notification {
    position: absolute;
    width: 12px;
    height: 12px;
    display: inline-block;
    background: #FF4588;
    border-radius: 22px;
    right: 0px;
    top: 0px;
    border: 1px solid #fff;
}

header #dropdown-basic {
    background: transparent !important;
    padding: 0;
    border: 0;
}

header .navbar-nav {
    align-items: center;
}

.export-btn {
    background: var(--mixup, linear-gradient(268deg, #FFCBD7 2.72%, #FFE4BE 98.87%));
    border: 0;
    width: fit-content;
    font-weight: 500;
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
    color: var(--bs-btn-active-color);
    background-color: var(--bs-btn-active-bg);
    border-color: 0;
}

.btn-check:focus+.btn,
.btn:focus {
    box-shadow: none;
}

.notification-txt {
    box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.08);
    padding: 21px;
    border-radius: 18px;
    margin-bottom: 14px;
}

.notification-txt p {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    margin-bottom: 0;
    margin-top: 0;
}

.date-txt {
    color: rgba(0, 0, 0, 0.70);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.outer-loader {
    position: fixed;
    top: 0;
    background: #000000b8;
    width: 100%;
    height: 100%;
    z-index: 1056;
}

.loader {
    width: 48px;
    height: 48px;
    margin: auto;
    position: absolute;
    z-index: 9999;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.loader:before {
    content: '';
    width: 48px;
    height: 5px;
    background: #000;
    opacity: 0.25;
    position: absolute;
    top: 60px;
    left: 0;
    border-radius: 50%;
    animation: shadow 0.5s linear infinite;
}

.loader:after {
    content: '';
    width: 100%;
    height: 100%;
    background: #fff;
    animation: bxSpin 0.5s linear infinite;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 4px;
}

@keyframes bxSpin {
    17% {
        border-bottom-right-radius: 3px;
    }

    25% {
        transform: translateY(9px) rotate(22.5deg);
    }

    50% {
        transform: translateY(18px) scale(1, .9) rotate(45deg);
        border-bottom-right-radius: 40px;
    }

    75% {
        transform: translateY(9px) rotate(67.5deg);
    }

    100% {
        transform: translateY(0) rotate(90deg);
    }
}

@keyframes shadow {

    0%,
    100% {
        transform: scale(1, 1);
    }

    50% {
        transform: scale(1.2, 1);
    }
}

.order-history-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.down-arrow {
    position: absolute;
    top: 15px;
    right: 11px;
    cursor: pointer;
    pointer-events: none;
}

.date-text {
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 3px 14px 0px rgba(0, 0, 0, 0.09);
    padding: 10px;
    margin: 0 !important;
    color: var(--Black-90, #2C2D33);
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.date-text img {
    margin-right: 10px;
}

.customer-detailsl-box {
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 3px 14px 0px rgba(0, 0, 0, 0.09);
    padding: 12px 23px 1px 23px;
    margin-top: 13px;
    height: 100%;
}

.customer-txt {
    color: var(--table-text, #292929);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0 0 13px 0 !important;
}

.customer-name {
    color: var(--table-text, #292929);
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0 0 7px 0 !important;
}

.customer-profile-img {
    display: flex;
    align-items: center;
}

.customer-id {
    color: var(--TEXT-COLOR-INPUT, #626660);
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 0 !important;
    margin-bottom: 4px !important;
}

.customer-profile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 7px;
}

.customer-profile-img img {
    margin-right: 8px;
    width: 38px;
    height: 38px;
    border-radius: 42px;
    object-fit: cover;
}

.status-txt h4 {
    color: var(--table-text, #292929);
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 131.9%;
    text-transform: capitalize;
}

.status-txt .delivered {
    width: 14px;
    height: 14px;
}

.status-txt {
    display: flex;
    align-items: center;
}

.customer-info p {
    color: var(--TEXT-COLOR-INPUT, #626660);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0;
    margin-top: 5px;
}

.shipping-box {
    display: flex;
    justify-content: flex-end;
}

.shipping-box-inner {
    display: flex;
    justify-content: space-between;
}

.shipping-box-inner p:first-child {
    margin-right: 24px;
}

.shipping-box-inner p {
    margin-bottom: 0px;
    color: var(--Neutral-Gray-Gray-500, #667085);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.09px;
}

.payment-status {
    color: var(--green-color, #00A850);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    border-radius: 5px;
    background: rgba(0, 168, 80, 0.22);
    letter-spacing: 0.06px;
    display: inline-block;
    padding: 5px 13px;
    margin-bottom: 0;
}

.text-right {
    text-align: right;
}

.number-box {
    display: inline-block;
    border-right: 1px solid rgba(0, 0, 0, 0.10);
    padding: 16px 18px;
    font-size: 10px;
    color: #112f49;
}

.hostory-box p {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.10);
    padding-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 17px;
}

.text-box {
    padding-left: 19px;
}

.hostory-box {
    box-shadow: 0px 1px 19px 0px rgba(0, 0, 0, 0.08);
}

.customer-fedback-img {
    display: flex;
    align-items: flex-start;
    margin-top: 12px;
}

.customer-fedback-img img {
    min-width: 61px;
    min-height: 61px;
    height: 61px;
    width: 61px;
    border-radius: 72px;
    margin-right: 13px;
    object-fit: cover;
}

.product-images-preview {
    width: 272px;
    height: 272px;
    border-radius: 20px;
    padding: 0px 20px;
    background: #c7c7c724;
    object-fit: contain;
}

.customer-box {
    margin-bottom: 23px;
    border-radius: 0px 0px 10px 10px;
    background: #FFF;
    box-shadow: 0px 1px 19px 0px rgb(0 0 0 / 13%);
    padding: 12px 25px 25px 25px;
}

.star-pattern {
    display: flex;
    align-items: center;
}

.star-pattern i {
    color: #FFC107;
    font-size: 17px;
    margin-right: 1px;
}

.text-star {
    margin-top: 10px;
    margin-left: 9px;
}

.notification-txt h4 {
    color: var(--H4-COLOR, #112F49);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    margin-bottom: 7px;
}

.search-box {
    margin-right: 20px;
}

.green-btn.green-outline-btn,
.green-btn.green-outline-btn:hover {
    border: 2px solid #00a850;
    background-color: transparent !important;
    color: #00a850;
    font-weight: 600;
    margin-left: 12px;
}
.red-btn.red-outline-btn,
.red-btn.red-outline-btn:hover {
    border: 2px solid #dc3545;
    background-color: transparent !important;
    color: #dc3545;
    font-weight: 600;
    margin-left: 12px;
}
.btn:hover {
    color: var(--bs-btn-hover-color);
    background-color: #00a850;
    border-color: #00a850;
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active,
.btn-check:focus+.btn-primary,
.btn-primary:focus {
    background-color: #00a850 !important;
    box-shadow: none;
}

.profile-img {
    text-align: center;
    position: relative;
    width: 203px;
    height: 203px;
    object-fit: cover;
    border-radius: 33px;
    margin: 0 auto;
}

.green-txt {
    color: var(--green-color, #00A850) !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: lowercase;
    text-decoration: underline;
}

.login-body img {
    width: 20px;
    margin-right: 4px;
}

.otp-text {
    color: #00A850 !important;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    margin-bottom: 15px;
}

.under-profile .customer-info {
    margin-bottom: 42px;
}

.otp-box .form-control {
    border-radius: 5px;
    border: 1px solid var(--green-color, #00A850);
    width: 15%;
    height: 15%;
    margin-right: 15px;
}

.otp-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
}

header .dropdown-menu {
    border-radius: 18px;
    box-shadow: 0px 1.127px 15.775px 0px rgba(0, 0, 0, 0.15);
    border: none;
    padding: 0;
}
.green-btn.btn.text-light.ms-3.map-button {
    margin: 12px 42px !important;
}
header .dropdown-item.active,
header .dropdown-item:active {
    background-color: #00a850 !important;
}

header .dropdown-item {
    font-size: 14px;
    font-weight: 500;
    padding: 8px 21px;
    border-bottom: 1px solid #00000021;
}

header .dropdown-item:last-child {
    border-bottom: 0;
}

header .dropdown-item:hover {
    color: #fff;
    background-color: #00a850;

}

header .dropdown-item:last-child:hover {
    border-radius: 0 0 18px 18px;
}

header .dropdown-item:first-child:hover {
    border-radius: 18px 18px 0 0;
}

.login-body .react-tel-input .form-control {
    height: 57px;
    width: 100%;
    padding-left: 51px;
    font-size: 1rem;
}

.login-bod .react-tel-input .flag-dropdown {
    background-color: #ffffff;
    border: 1px solid #E3E3E3;
    padding: 6px;
}

.profile-outer-box {
    border-radius: 20px;
    border: 1px solid rgba(0, 168, 80, 0.37);
    background: #FFF;
    box-shadow: 0px 1px 24px 0px rgba(0, 0, 0, 0.15);
    padding: 18px 36px;
}

.profile-img input {
    width: 42px;
    height: 42px;
    position: absolute;
    bottom: 10px;
    right: -14px;
    opacity: 0;
}

.edit-icon {
    position: absolute;
    bottom: 5px;
    right: -16px;
    pointer-events: none;
}

.text-decoration-one {
    text-decoration: none;
}
.under-profile .row {
    margin-top: 13px;
}

.review-txt {
    color: #292929;
    font-size: 17px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 0;
}

.profile-details {
    color: var(--table-text, #292929) !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important;
    margin-top: 15px !important;
}

.ratings {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.review-heading {
    color: #133240;
    text-align: left;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 6px;
}
.white-space-inherit{
    white-space: unset !important;
}

.subheading-under.subheading-under-box {
    font-size: 25px;
    margin-bottom: 17px;
}

.contact-info div {
    margin-left: 22px;
}

.contact-info {
    display: flex;
    align-items: flex-start;
}

.contact-us-section .login-body .react-tel-input .form-control {
    height: 47px;
}

.product-img img {
    width: 65px;
    min-width: 65px;
    height: 65px;
    border-radius: 6px;
    object-fit: cover;
    margin: 0 19px;
    border: 1px solid #FFCBD7;
}

.pagination-tab {
    border-radius: 11.098px;
    background: #FFF;
    box-shadow: 0px 3.177px 3.177px 0px rgba(0, 0, 0, 0.20);
    text-align: right;
    display: inline-block;
    padding: 9px 22px;
    margin-top: 12px;
}

.pagination-tab .pagination li a {
    color: #656565;
    padding: 0 14px;
    text-decoration: none;
}

.pagination-tab .pagination li.active {
    background: #000;
    border-radius: 3.329px;
    background: var(--green-color, #00A850);
    padding: 6px 0px;
}

.pagination li:first-child,
.pagination li:last-child {
    display: none;
}

.pagination-tab .pagination li.active a {
    color: #fff !important;
}

.pagination-tab .pagination {
    align-items: center;
}

.stock-shadow .fa.fa-trash {
    background: #FF5F62;
    padding: 12px;
    color: #fff;
    border-radius: 22px;
    cursor: pointer;
    margin-bottom: 23px;
}

.my-profile-img {
    width: 203px;
    height: 203px;
    object-fit: cover;
    border-radius: 21px;
    border: 2px solid #00a850;
}

.swal-overlay--show-modal .swal-modal {
    border-radius: 22px;
}

.swal-button.swal-button--confirm,
.swal-button.swal-button--confirm:hover {
    background: #00a850;
    border-radius: 22px;
}

.swal-footer {
    text-align: center;
}

.swal-title {
    color: rgb(0 0 0);
    font-weight: 700;
}

.default-profile-img {
    width: 52px;
    height: 52px;
    border-radius: 42px;
    object-fit: cover;
}

.create-profile-section.common-padding {
    padding-top: 0;
}

.modal-success .btn-close {
    position: absolute;
    right: 30px;
    top: 21px;
    z-index: 999;
    cursor: pointer;
}

.add-product-section .product-img img {
    margin: 0 10px;
}

.product-img img {
    margin: 0 10px 0 0;
}

.edit_btn {
    color: #00a850;
    cursor: pointer;
}

.profile-img .text-danger {
    position: absolute;
    bottom: -54px;
    width: 452px;
    right: -70%;
    font-size: 12px;
}

.customer-id.rejection-reason {
    color: red;
}

.terms-condition {
    color: #00a850;
    font-weight: 600;
    text-decoration: none;
}

.action-data-box {
    display: flex;
    align-items: center;
}

.fa.fa-eye.active {
    color: #05a851;
}

.action-data-box .fa-eye {
    color: #A7B7DD;
    font-size: 23px;
    margin-top: 12px;
}

.delivered.accepted {
    background: #BF1722;
}

.delivered.not-assigned {
    background: #FF6347;
}

.delivered.driver-assigned {
    background: #1290E0;
}

.delivered.pending {
    background: #F3A638;
}


.cancel-btn-outline.btn-check:checked+.btn,
.cancel-btn-outline.btn.active,
.cancel-btn-outline.btn.show,
.cancel-btn-outline.btn:first-child:active,
:not(.btn-check)+.cancel-btn-outline.btn:active,
.cancel-btn-outline.btn-check:focus+.cancel-btn-outline.btn-primary,
.cancel-btn-outline.btn-primary:focus {
    background-color: transparent !important;
    color: #FF4848;
}

.action-outer .green-btn.radius-btn {
    padding: 7px 15px;
    right: 0;
    font-size: 10px;
    margin-left: 11px;
    font-weight: 600;
}

.action-outer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 11px;
}

.customer-profile.customer-profile-delivery-box {
    margin: 12px 0 27px 0;
}

.nodata.centre img {
    width: 95px;
}

.nodata.centre {
    display: flex;
    align-items: center;
    padding: 62px 0;
    flex-direction: column;
    text-transform: capitalize;
}

.nodata.centre span {
    display: inline-block;
    margin-top: 12px;
}

.search-box .fa.fa-times {
    position: absolute;
    top: 11px;
    right: 17px;
    color: #00a850;
    cursor: pointer;
}

.google-location .css-13cymwt-control,
.google-location .css-t3ipsp-control {
    padding: 10px;
    border-radius: 12px;
    border: 1px solid #E3E3E3;
}

.google-location .css-1xc3v61-indicatorContainer,
.google-location .css-1u9des2-indicatorSeparator,
.google-location .css-15lsz6c-indicatorContainer {
    display: none;
}

.swal-button,
.swal-button:hover {
    border-radius: 42px !important;
    border: none !important;
}

.swal-button--cancel:focus {
    box-shadow: unset !important;
}

.hostory-box {
    box-shadow: 0px 1px 19px 0px rgba(0, 0, 0, 0.08);
    max-height: 325px;
    height: 100%;
    overflow: auto;
}

::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #aba9ad;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #00A850;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.add-new-product {
    padding-left: 52px !important;
}

.description-txt.main-add-description {
    min-width: 322px;
}

.search-img {
    cursor: pointer;
}

/* .stock-trash {
    position: absolute;
    top: 36px;
    right: 0;
} */
.css-1n6sfyn-MenuList {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
}

.fa-eye {
    color: #A7B7DD;
}

.green-btn.radius-btn.search-btn.btn.btn-primary {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.otp-box input {
    /* padding: 23px 31px; */
    width: 52px !important;
    height: 52px;
    border: 1px solid #00A850;
    border-radius: 4px;
    margin-right: 7px;
}

.view-stock-history {
    max-height: 313px;
    overflow: auto;
    height: 100%;
}

.cursor-pointer {
    cursor: pointer;
}

.preview-box-modal .swiper-button-prev,
.preview-box-modal .swiper-button-next {
    background: #00A850;
    width: 32px;
    height: 32px;
    border-radius: 22px;
    cursor: pointer !important;
}

.preview-box-modal .swiper-button-prev:after,
.preview-box-modal .swiper-rtl .preview-box-modal .swiper-button-next:after {
    content: 'prev';
    font-size: 16px;
    color: #fff;
}

.preview-box-modal .swiper-button-next:after,
.preview-box-modal .swiper-rtl .preview-box-modal .swiper-button-prev:after {
    content: 'next';
    font-size: 16px;
    color: #fff;
}

.preview-box-modal .modal-content {
    border-radius: 22px;
    padding: 19px;
}

.preview-box-modal .swiper-button-prev {
    left: 0;
}

.preview-box-modal .swiper-button-next {
    right: 0;
}

.add-inventory .form-control {
    margin: 0 12px !important;
    width: 179px !important;
}

.add-inventory .w-100.ms-3.css-b62m3t-container {
    width: 152px !important;
}

.add-inventory .form-label {
    margin: 12px 12px !important;
}



.loader-search {
    width: 28px;
    height: 28px;
    border: 5px solid #00a850;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    margin-right: 12px;
}

.search-near-driver.btn.btn-primary {
    background: transparent !important;
    box-shadow: unset !important;
    color: #00a850;
    padding: 0;
    display: flex;
    align-items: center;
    font-size: 19px;
    font-weight: 600;
    border: none;
}

.product-card-img {
    min-width: 33px;
    cursor: pointer;
}

.green-btn.radius-btn.cancel-btn-outline.reject-button.btn.btn-primary {
    border: 1px solid;
    cursor: pointer;
}

.cancel-btn-outline.btn.btn-primary {
    color: red;
    background: transparent;
    border: none;
    font-weight: 700;
    cursor: unset;
}

.delivered-status,
.delivered-status:hover {
    background: transparent;
    color: #00a850;
    font-weight: 700;
    padding: 0;
    box-shadow: unset;
    text-decoration: none;
}
.otp-display.hd-otp {
    font-size: 15px;
    font-weight: normal;
    color: #282828;
    background: #ffd0d240;
    padding: 0px 12px;
    display: inline-block;
    border-radius: 5px;
    border: 1px solid #ffd0d2f7;
    margin-top: 7px;
}
.otp-text-pr {
    font-weight: 600;
}
/* add key frames for search loader */
@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* add key frames for search loader */

.border-error {
    border-color: red !important;
    border: 1px solid;
    border-radius: 11px;
}

.errors {
    color: red !important;
}

.profile-details.document-text {
    border: 1px solid #00a850;
    display: inline-block;
    padding: 7px 14px;
    border-radius: 10px;
    color: #00a850 !important;
    font-size: 13px !important;
}

.profile-details.document-text i {
    margin-right: 7px;
}

button.btn-close {
    opacity: unset;
}

.notification-txt a {
    text-decoration: none;
}

.main-add-description {
    font-size: 12px;
    line-height: 18px;
    width: 352px;
    max-height: 72px;
    overflow: auto;
    height: 100%;
    padding-right: 12px;
    /* float: right; */
}

.multiple-product-img i {
    position: absolute;
    bottom: -16px;
    right: 38px;
    border-radius: 22px;
    font-size: 11px;
}

.preview-images-section .swiper-slide {
    margin: 0 auto;
    text-align: center;
}

.product-outer-main {
    display: flex;
    align-items: center;
}

.mobile-view {
    display: none;
}

button.export-btn.btn.btn-primary {
    color: #000;
}

.swal-text {
    text-align: center;
}

.cancel-btn-outline.order-cancelled.btn.btn-primary {
    color: #F3A638;
}

.cancel-btn-outline.rejected-status {
    color: #BF1722 !important;
}

.profile-outer-box .star-pattern {
    align-items: center;
    display: flex;
    justify-content: center;
}

.profile-outer-box .review-txt {
    display: flex;
    align-items: center;
    justify-content: center;
}

.transaction_history_boxes p {
    margin-bottom: 0;
    margin-top: 0;
}

.invoice {
    color: #00a850;
    text-decoration: none;
    font-size: 15px;
}

.receive-status {
    color: #00a850;
    background: #00a8501f;
    display: inline-block;
    padding: 2px 20px;
    border-radius: 10px;
}

.transfer-status {
    color: #00a850;
    background: #00a8501f;
    display: inline-block;
    padding: 2px 20px;
    border-radius: 10px;
}

.transfer-status {
    color: #ff0000;
    background: #ff00001c;
    display: inline-block;
    padding: 2px 20px;
    border-radius: 10px;
}

.upload-document {
    width: 132px;
    height: 132px;
    background: #00000017;
    border: 1px dashed gray;
    border-radius: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.upload-document input {
    opacity: 0;
    width: 102px;
    height: 102px;
}

.upload-document .file-upload {
    position: absolute;
    top: 50%;
    width: 47px !important;
    left: 50%;
    cursor: pointer;
    background: #fff;
    padding: 4px;
    margin-right: 0 !important;
    transform: translate(-50%, -50%);
    border-radius: 32px;
    padding: 10px;
    height: 47px;
    cursor: pointer;
}

.upload-document .file-upload-show {
    position: absolute;
    top: 50%;
    width: 100% !important;
    left: 50%;
    cursor: pointer;
    background: #fff;
    padding: 4px;
    margin-right: 0 !important;
    transform: translate(-50%, -50%);
    padding: 10px;
    height: 100%;
    cursor: pointer;
        border-radius: 13px;
}

.upload-text {
    font-size: 15px;
    margin-top: 11px;
}

.profile-id-bottom {
    display: flex;
}

.profile-id-bottom h5.upload-text {
    font-size: 12px;
}

header .nav-link.active {
    color: #05a851 !important;
    font-weight: 600;
}

.head-top-notifications button#dropdown-basic:focus {
    box-shadow: unset;
}

.head-top-notifications {
    position: relative;
}

.head-top-notifications .dropdown-menu.show {
    position: absolute;
    left: -290px !important;
    width: 350px;
    top: 30px;
    overflow: hidden;
}

.head-top-notifications .dropdown-menu.show a p {
    margin: 0;
    font-size: 12px;
    text-align: right;
    color: #a8a8a8;
    font-weight: 500;
}

.notification-content-top {
    max-height: 350px;
    overflow: auto;
}

a.notifications-view {
    padding: 8px 21px;
    display: flex;
    justify-content: end;
    color: #09a751;
    font-weight: 600;
    font-size: 14px;
}

.head-top-notifications .dropdown-menu.show a {
    white-space: unset;
    font-size: 13px;
    line-height: 21px;
    padding: 6px 12px;
}

.head-top-notifications .dropdown-menu.show a:hover p {
    color: #fff;
}

.head-top-notifications .dropdown-menu.show a:hover {
    border-radius: 0;
}

.id-proofs-section {
    overflow: hidden;
}

.id-proofs-section img {
    height: 100px;
    min-width: 100px;
    min-height: 100px;
    width: 100px;
    border-radius: 10px !important;
}

.id-proofs-section input {
    z-index: 9;
    position: absolute;
    top: 0;
    left: 0;
}

.red-text {
    color: red !important;
}

/* Custom marker label style */
/* stock input css*/
.cart-input {
    display: flex;
    align-items: center;
}

.quantity-input {
    width: 40px !important;
    text-align: center;
    padding: 0% !important;
}

.plus-btn, .minus-btn, .plus-btn:hover, .minus-btn:hover {
    width: 30px;
    height: 30px;
    font-size: 18px;
    border: none;
    cursor: pointer;
    outline: none;
    background: #00a850;
    color: #fff;
    border-radius: 22px;
    margin: 0 11px;
}

.verfiy-account-section {
    background: url("../images/banner-section.png") no-repeat;
    background-position: center;
    display: flex;
    background-size: cover;
    align-items: center;
    justify-content: center;
}

.verify-content-box h5 {
    font-size: 41px;
    font-weight: 700;
    margin-bottom: 3px;
}

.verify-content-box img {
    width: 24%;
    margin-bottom: 10px;
}
.table.product-view-table th:first-child {
    padding-left: 14px;
}

.table-data table.product-view-table th {
    padding: 14px 5px;
    vertical-align: top;
    font-size: 18px;
}

.small-text-main {
    font-size: 12px;
    display: block;
}

.quantity-txt {
    position: absolute;
    right: 8px;
    background: red;
    display: inline-block;
    width: 17px;
    height: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 12px;
    font-size: 8px;
    top: 1px;
    pointer-events: none;
}

.quantity-box .form-control {
    width: 26%;
    text-align: center;
}
.login-body.quantity-box-inner {
    display: flex;
    align-items: center;
}
/* .quantity-box .cart-input {
    display: flex;
    align-items: center;
    margin-top: 21px;
    justify-content: center;
} */
.quantity-box .text-danger {
    text-align: left;
    display: block;
    margin-top: 13px;
}
.quantity-box .green-btn{
    margin-top: 0 !important;
    width: fit-content !important;
}
.tooltip {
    z-index: 0;
}
.price-text-add-product {
    min-width: 182px;
}
.review-section .star-pattern span {
    font-size: unset !important;
    margin-bottom: 8px;
}
.fade.toast.quantity-box.show {
    position: absolute;
    max-width: 190px !important;
    top: -30px;
}  
.graph-section {
    margin: 40px 0;
    border: 1px solid #E0E2E7;
    padding: 41px;
    border-radius: 22px;
}
.transaction_history_boxes table tr {
    white-space: nowrap;
}
.flag-select.select-language {
    background: #00a850;
    border-radius: 6px;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.25);
    margin-left: 16px;
    padding-bottom: 3px !important;
}  
.flag-select__btn:after {
    border-top: 5px solid #ffffff;
}
.flag-select__btn[aria-expanded=true]:after {
    border-bottom: 5px solid #ffffff;
}
.select-language  .flag-select__btn {
    color: #ffffff;
}
.select-language .flag-select__option {
    padding: 4px;
    margin: 0;
    font-size: 14px;
}
.select-language .flag-select__options {
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    width: 100%;
    padding: 0 !important;
    border: none;
}
.select-language .flag-select__option.has-label {
    padding: 8px 10px !important;
    color: #000;
    font-family: 'Inter', sans-serif;
}
.select-language .flag-select__option:not(.flag-select__option--placeholder):hover {
    background: #00a850; 
    color: #fff !important;
}
.common-tabs {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.go-status {
    background: #fff;
    padding: 7px 15px;
    border-radius: 11px;
    border: 1px solid #0000003d;
    text-align: center;
    display: flex;
    align-items: center;
    box-shadow: 0px 4px 10px 0px rgba(175, 137, 255, 0.15);
}
.go-status span {
    color: #000;
    font-size: 13px;
    display: inline-block;
    margin-right: 14px;
    font-weight: 700;
    white-space: nowrap;
}
/* Reset default list styles */
.privacy-txt-li {
    list-style-type: none;
    counter-reset: my-counter;
    color: #00000087;
    text-align: justify;
    line-height: 34px;
}
  
  /* Style for list items */
  .privacy-txt-li li {
    counter-increment: my-counter; /* Increment the counter for each list item */
    margin-bottom: 0.5em; /* Adjust spacing between list items */
  }
  
  /* Style for the numbering */
  .privacy-txt-li li::before {
    content: counter(my-counter); /* Display the counter */
    margin-right: 0.5em; /* Adjust spacing between counter and list item */
    font-weight: bold; /* Optional: Make the numbers bold */
  }
.choose-under-content p {
    margin-bottom: 0;
}
.under-choose-box {
    width: 73%;
    margin: 0 auto;
}
.pick-date {
    position: relative;
    margin-right: 12px;
}
.date-time-section {
    display: flex;
}
.no-content.privacy-txt {
    display: none;
}

.heading-top-area-right.date-range-filter {
    margin: 10px 0 5px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.heading-top-area-right.date-range-filter label {
    padding: 0 5px;
    color: #000;
    font-size: 14px;
    font-weight: 600;
}
.date-range-filter .react-datepicker-wrapper input {
    background: #fff;
    border: 1px solid #00000029;
    border-radius: 10px;
    color: #000;
    cursor: pointer;
    font-size: 13px;
    font-weight: 600;
    padding: 5px 10px;
}
.apply-top-btn {
    background: #00a652;
    border-radius: 8px!important;
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    margin: 20px 0 0 6px;
    padding: 5px 15px;
}
.apply-top-btn.clear-top-btn {
    background: none;
    border: 2px solid #00a652;
    color: #00a652;
    padding: 3px 15px;
}
.pick-date i {
    position: absolute;
    color: #80808096;
    top: 12px;
    right: 7px;
    font-size: 13px;
}
.pick-date {
    position: relative;
}
.dateTime{
    font-size: 14px;
}
.no-found {
    text-align: center;
    padding: 32px;
}
.dropdown-toggle::after {
    position: absolute;
    top: 23px;
}
.alert b {
    margin: 0 7px 0px 4px;
}
.about-us-section {
    background: url("../images/about-us-bg.png") no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    padding: 80px 0;
    border-radius: 0 0 52px 52px;
    color: #fff;
    text-align: center;
    background-size: cover;
}
.privacy-policy-section p {
    line-height: 30px;
    margin-bottom: 13px;
}
.text-justify{
    text-align: justify;
}
.bank-pick-date .react-datepicker-wrapper {
    width: 100% !important;
}
.bank-pick-date{
    margin-right: 0 !important;
}
.pick-date.bank-pick-date i {
    top: 17px;
    right: 17px;
}
.privacy-policy-section ul li {
    color: #00000087;
    line-height: 27px;
    font-size: 16px;
}
.pick-date.bank-pick-date input {
    cursor: pointer;
}
span.outer-main-span {
    display: flex;
    align-items: center;
}
.accept-order {
    background: #00a850;
    border: none;
    border-radius: 32px;
    font-size: 12px;
    margin-right: 6px;
}
.accept-order.reject-order {
    border: 1px solid red;
    background: transparent !important;
    color: red;
}
.timer-section p {
    margin: 0;
    font-size: 13px;
    color: #00a850;
    font-weight: 600;
    text-align: left;
    border: 1px solid #00a8504f;
    border-radius: 22px;
    text-align: center;
    padding: 0;
    height: 32px;
    margin-top: 7px;
    width: fit-content;
    padding: 0 18px;
    justify-content: center;
}
.timer-section {
    margin: 0 auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.outer-progress-bar {
    margin: 12px 0;
}

.progress-text {
    position: absolute; 
    color: white;
    font-size: 12px;
    font-weight: bold;
  }

.outer-progress-bar .progress-bar {
    background-color: #00a850;
    padding: 8px;
    font-size: 11px;
    width: 100%;
}
.outer-progress-bar .progress-bar-second-half{
    background-color: #FF9500;
}
.outer-progress-bar .progress-bar-third-half{
    background-color: #E82D2C;
}


.outer-progress-bar .progress {
    border-radius: 22px;
    height: 23px !important;
}
.outer-progress-bar .progress-bar.two-minute-status{
    background-color: #F3B100;
}
.outer-progress-bar .progress-bar.two-minute-status.four-minute-ten-status{
    background-color: #FF0303;
}
.position-relative.outer-position-relative {
    width: 25%;
    margin-top: 15px;
    text-align: right;
    margin-left: auto;
}
.under-list ul {
    margin-left: 20px;
    margin-bottom: 20px;
    font-weight: 600;
    margin-top: 4px;
}
.d-none{
    display: none;
}
.paymeny-success-section {
    text-align: center;
    height: 61vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.paymeny-success-section h3 {
    font-size: 30px;
    font-weight: 700;
    margin: 11px 0 0 0;
}
.paymeny-success-section img {
    width: 109px;
}
/* responsiveness start */
@media (max-width: 1600px) {
    .verfiy-account-section {
        padding: 32px 0;
    }
}

@media (max-width: 1440px) {
    .hero-left-content h1 {
        font-size: 28px;
    }
    .price-text-add-product {
        padding-left: 24px !important;
    }

    .add-inventory {
        width: 100%;
        overflow: auto;
    }

    .green-btn {
        padding: 8px 22px;
        white-space: nowrap;
    }

    .hero-left-content h3 {
        letter-spacing: 0px;
    }

    .subheading {
        font-size: 34px;
    }

    .table-data {
        width: 100%;
        overflow: auto;
    }

    #product-management p {
        font-size: 13px;
        white-space: nowrap;
    }

    #product-management .table-data table th {
        font-size: 16px;
        white-space: nowrap;
    }
}

@media (max-width: 1399px) {
    .common-tabs .nav-tabs .nav-link {
        margin-bottom: 8px;
    }
}

@media (max-width: 1199px) {
    header .navbar-light .navbar-nav .nav-link {
        margin-left: 12px;
    }
    .action-data-box.updated-action-data-box img {
        min-width: 28px;
    }
    .action-data-box.updated-action-data-box .action-btn {
        display: flex;
    }
    .status-txt-main {
        white-space: nowrap;
    }
    .search-box .form-control {
        border-radius: 10px;
        background: #FFF;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.10) inset;
        padding-left: 42px;
        padding-right: 30px;
        width: 222px;
        font-size: 14px;
        padding-top: 8px;
        padding-bottom: 8px;
        overflow: hidden !important;
        -webkit-line-clamp: 1;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
    }

    .notification-txt h4 {
        font-size: 19px;
    }

    .notification-txt p {
        font-size: 13px;
    }

    .notification-txt {
        padding: 14px 17px 9px 17px;
    }

    .login-body .form-label {
        font-size: 15px;
    }

    .subheading-under {
        font-size: 20px;
    }

    .status-bar .form-select {
        font-size: 14px;
    }

    .table-data table td,
    .table-data table th {
        font-size: 14px;
    }

    .login-form-box {
        padding: 39px;
    }

    .testimonial-box:after {
        left: 62px;
    }

    .hero-left-content h1,
    .subheading {
        font-size: 27px;
    }

    .hero-left-content h3,
    .under-subheading {
        font-size: 20px;
    }

    .form-newslater {
        padding: 0;
    }

    .newslater-box {
        padding: 86px;
    }
    .graph-section {
        padding: 12px;
    }
}

@media (max-width: 992px) {
    .hero-bannner-section {
        height: unset;
        background-position: center;
        padding-top: 12px;
    }
    .dashboard-management.common-under-padding.status-bar.justify-content-between h2 {
        padding-right: 43px;
    }
    #product-management .table-data table th {
        padding-left: 19px;
    }

    .verify-content-box h5 {
        font-size: 22px;
    }

    .search-box .form-control {
        width: 200px;
    }

    .customer-profile.customer-profile-delivery-box {
        margin: 4px 0;
    }

    .customer-detailsl-box {
        padding: 12px 23px 0 23px;
    }

    .green-btn.radius-btn.cancel-btn-outline {
        padding: 13px 55px;
    }

    .common-tabs .nav-tabs .nav-link {
        margin-bottom: 9px;
    }

    .add-stock-section .form-control {
        width: 100%;
    }

    .outer-product-img {
        flex-wrap: wrap;
    }

    .product-img-box {
        margin-right: 2px;
        margin-bottom: 6px;
    }

    .cultivatorss-section .swiper-button-prev,
    .cultivatorss-section .swiper-button-next {
        left: 70px;
    }

    .testimonial-box .row {
        flex-direction: column-reverse;
    }

    .status-txt h4 {
        font-size: 18px;
    }

    .customer-profile {
        margin-bottom: 14px;
    }

    .shipping-box-inner p {
        font-size: 14px;
    }

    .green-btn.radius-btn {
        padding: 13px 52px;
    }

    .stock-shadow {
        border-radius: 18px;
        padding: 15px 15px;
    }

    .number-box {
        padding: 8px 14px;
    }

    .hostory-box p {
        font-size: 15px;
    }

    .latest-order-txt {
        margin-top: 12px;
    }

    .customer-box {
        padding: 12px 15px 15px 15px;
        margin-top: 12px;
    }

    .container {
        max-width: 940px !important;
    }

    header .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .login-section {
        padding: 33px 0;
    }

    .file-upload {
        top: 54px;
        width: 31px !important;
        right: 8px;
        background: #fff;
        padding-left: 4px;
    }

    .testimonial-box:after {
        left: 49px;
    }

    .cultivatorss-section .swiper-button-prev:after,
    .cultivatorss-section .swiper-button-next:after {
        font-size: 12px;
    }

    .cultivatorss-section .swiper-button-next,
    .cultivatorss-section .swiper-button-prev {
        width: 32px;
        height: 32px;
    }

    .cultivatorss-section .swiper-button-prev {
        left: 22px !important;
    }

    .cultivator-content {
        padding: 62px 20px;
    }

    .hero-left-content {
        text-align: center;
        padding-bottom: 26px;
    }

    .marketplace-content {
        text-align: center;
        margin-top: 22px;
    }

    .cultivatorss-img {
        text-align: center;
    }
}

@media (max-width: 991px) {
    header  .join-us-now-btn, .join-us-now-btn:hover {
        margin-bottom: 16px;
    }
    .dot-notification{
        right: -12px;
    }
    .status-bar {
        flex-direction: column;
    }
    .subheading-under {
        margin-bottom: 15px;
    }
    .mobile-view {
        display: block;
        color: #000;
    }
    .review-section .star-pattern i {
        font-size: 15px;
    }
    .review-section .customer-box {
        margin-top: 0;
        margin-bottom: 11px;
    }
    .review-section .ratings {
        margin-bottom: 6px !important;
    }

    .add-product .search-box {
        margin-right: 10px;
    }

    .desktop-view {
        display: none;
    }

    .search-box {
        margin-right: 0;
    }
}

@media (max-width: 768px) {
    .date-range-filter .react-datepicker-wrapper input {
        width: 100%;
        min-width: 140px;
    }
    .about-us-section {
        padding: 30px 0;
    }
    .about-us-section h2 {
        font-size: 24px;
    }
    .about-outer-section h5 {
        font-size: 16px !important;
        line-height: 24px;
    }
    .about-outer-section .common-padding {
        padding: 16px 0;
    }
    .head-top-notifications .dropdown-menu.show {
        left: 0!important;
        min-width: 300px;
        width: 100%;
    }
    .under-choose-box {
        width: 100%;
    }
    .common-tabs .nav-tabs .nav-link {
        margin-left: 10px;
    }
    .privacy-policy-section {
        padding: 0 22px;
    }
    .privacy-policy-section h2 {
        font-size: 27px;
    }
    .contact-info {
        margin-top: 41px;
    }

    .table-data table.product-view-table th {
        padding: 14px 9px;
    }

    .common-tabs .nav-tabs .nav-link {
        padding: 9px !important;
        font-size: 14px;
    }

    .dashboard-txt {
        font-size: 21.913px;
    }

    .dashboard-card {
        padding: 19px 18px;
    }

    .testimonial-box:after {
        left: 37px;
    }

}

@media (max-width: 767px) {
    .customer-info {
        margin-bottom: 18px;
    }
    .no-found h2 {
        font-size: 31px;
    }
    .under-heading {
        flex-wrap: wrap;
    }

    .product-header-area {
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .dashboard-card {
        margin-bottom: 9px;
        height: unset;
    }

    .under-heading {
        margin-top: 12px;
    }

    .common-tabs .nav-tabs .nav-link {
        white-space: nowrap;
    }

    .common-tabs .nav-tabs {
        justify-content: center;
    }

    .common-tabs .nav-tabs .nav-link {
        margin-bottom: 11px;
        padding-right: 30px !important;
    }

    .common-under-padding {
        padding: 19px 0;
    }

    .table-data table th,
    .table-data table td {
        white-space: nowrap;
    }

}

@media (max-width: 646px) {
    .form-newslater .form-control {
        padding-right: 37px;
    }
    .about-us-spanish .text-justify {
        text-align: unset;
        font-size: 16px;
        line-height: 26px;
    }
    .process-us-section.common-padding {
        padding: 0 0 48px 0;
    }
    .about-outer-section .newslater-box {
        padding: 36px 12px;
    }
    .about-us-section {
        padding: 15px 0;
        border-radius: 0;
    }
    .about-outer-section p {
        font-size: 15px;
    }
    .product-name {
        margin-right: 0;
    }
    .dashboard-card h5 {
        font-size: 12.95px;
    }
    .common-tabs {
        flex-wrap: wrap;
    }
    .go-status {
        margin-bottom: 12px;
    }
    button.apply-top-btn {
        margin: 9px 0 0 0 ;
        width: 60%;
    }
    .search-box .form-control {
        width: 135px;
    }

    .common-tabs .nav-item {
        margin-right: 12px;
    }

    .search-box {
        margin-right: 0;
    }

    .select-box-mobile.me-3 {
        margin-right: 12px !important;
    }

    .green-btn {
        padding: 8px 12px;
    }

    .status-bar .form-select {
        width: 150px;
    }

    .common-tabs .nav-tabs {
        flex-wrap: nowrap;
        width: 100%;
        overflow: auto;
        justify-content: start !important;
    }
}

@media (max-width: 576px) {
    .hostory-box p {
        padding-bottom: 10px;
        flex-direction: column;
        align-items: flex-start;
    }
    .privacy-policy-section h2 {
        font-size: 19px;
        margin-bottom: 6px;
    }
    .privacy-policy-section {
        padding: 0;
    }
    .privacy-txt-li {
        text-align: unset;
    }
    .privacy-policy-section ul li {
        font-size: 14px;
    }
    .about-outer-section h5, .about-outer-section p {
        font-size: 14px !important;
    }
    .status-txt {
        position: absolute;
        right: 20px;
        top: 10px;
    }
    .customer-detailsl-box {
        position: relative;
    }
    .head-top-notifications .dropdown-menu.show {
        min-width: 251px;
    }
    .dateTime {
        font-size: 12px;
        text-align: right;
        padding-left: 46px;
    }
    .number-box {
        padding: 8px 8px;
    }
    .text-box {
        font-size: 12px;
    }
    .newslater-box {
        border-radius: 0;
    }
    .quantity-box .form-control {
        width: 36%;
    }
    .graph-section {
        padding:4px 10px;
    }
    .footer-contents img {
        width: 92px;
    }
    .main-add-description {
        width: 302px;
    }

    .verify-content-box img {
        width: 40%;
    }

    .order-managemnt-section .search-box .form-control,
    .order-managemnt-section .status-bar .form-select {
        width: 100%;
    }

    .order-managemnt-section .under-heading {
        margin-top: 2px;
    }

    .common-tabs .nav-tabs .nav-link {
        padding-right: 23px !important;
    }

    .common-tabs .nav-tabs .nav-link img {
        margin-right: 5px;
        width: 13px;
    }

    .preview-box-modal .modal-header {
        padding-top: 0;
    }

    .status-bar .form-select {
        width: 96px;
    }

    .pagination-tab {
        padding: 6px 8px;
    }

    .pagination-tab .pagination li a {
        padding: 0 10px;
        font-size: 14px;
    }

    .pagination-tab .pagination li.active {
        padding: 3px 0px;
    }

    .notification-section .product-header-area {
        align-items: flex-start;
        padding-bottom: 1px;
    }

    .notification-txt {
        margin-bottom: 7px;
    }

    .stock-shadow .fa.fa-trash {
        padding: 9px;
        font-size: 11px;
    }

    .product-img-box img {
        width: 82px !important;
        height: 82px;
        border-radius: 11px;
    }

    .green-btn.radius-btn,
    .green-btn.radius-btn.cancel-btn-outline {
        padding: 13px 52px;
    }

    .green-btn.radius-btn {
        border: 2px solid #00a850;
    }

    .action-btn-add {
        text-align: center;
    }

    .product-header-area {
        flex-direction: column;
    }

    .form-newslater .form-control {
        left: 8px;
        position: relative;
    }

    .green-btn {
        padding: 8px 18px;
        right: 10px;
    }

    .login-header h2,
    .subheading-under {
        font-size: 25px;
        margin-bottom: 12px;
    }

    .login-form-box {
        padding: 21px 35px;
    }

    .text-danger {
        font-size: 13px;
    }

    .play-store-box a,
    .play-store-box a:hover {
        padding: 10px;
    }

    .create-profile-section.common-padding {
        padding: 0;
    }

    .newslater-box {
        padding: 86px 12px;
    }

    .service-inner {
        border: 0;
        box-shadow: unset;
    }

    .service-inner h2 {
        font-size: 45px;
    }

    .service-inner {
        border: 0;
        box-shadow: unset;
        padding: 33px;
    }

    .footer-contents ul li a {
        margin: 0 7px;
        font-size: 14px;
    }

    .social-media ul li {
        margin-left: 6px;
    }

    .my-profile-img,
    .profile-img {
        width: 153px;
        height: 153px;
    }

    .profile-outer-box {
        padding: 18px 16px;
    }

    .under-profile .customer-info {
        margin-bottom: 6px;
        background: #00a74f0d;
        padding: 13px;
        border-radius: 12px;
    }

    .profile-details {
        font-size: 15px !important;
        margin-top: 8px !important;
    }

    .add-inventory .form-control {
        width: 119px !important;
    }
}

@media (max-width: 480px) {
    .add-product-select {
        margin: 10px 0 0 0 !important;
    }
    .position-relative.outer-position-relative {
        width: 44%;
    }
    .quantity-box .modal-title {
        margin-right: 20px;
    }
    .customer-profile {
        flex-direction: column;
        align-items: flex-start;
    }

    .upload-document,
    .upload-document input {
        width: 92px;
        height: 92px;
    }

    .upload-text {
        font-size: 13px;
    }

    .add-product .search-box {
        margin-right: 0;
    }

    .search-box .form-control {
        width: 100%;
    }

    .under-heading {
        margin-top: 2px;
    }

    .common-tabs .nav-tabs .nav-link {
        font-size: 13px;
    }

    .common-tabs .nav-tabs .nav-link img {
        width: 12px;
    }

    .notification-txt h4 {
        font-size: 15px;
    }

    .search-near-driver.btn.btn-primary {
        font-size: 17px;
    }

    .loader-search {
        width: 24px;
        height: 24px;
    }

    .testimonial-box:after {
        left: 25px;
    }

    .product-images-preview {
        height: 182px;
        width: 182px;
    }

    .status-txt h4 {
        font-size: 11px;
        white-space: nowrap;
    }
    .status-txt {
        margin-left: 12px;
    }

    .action-outer .green-btn.radius-btn {
        padding: 3px 10px;
    }

    .green-btn.radius-btn,
    .green-btn.radius-btn.cancel-btn-outline {
        padding: 8px 21px;
    }

    .select-box-mobile.me-3 {
        margin-top: 9px;
        margin-right: 0 !important;
    }

    .search-box {
        margin-right: 0;
    }

    .select-box-mobile.me-3 select {
        margin-left: 0;
        width: 100%;
    }

    .under-heading {
        align-items: stretch;
    }

    .green-btn.green-outline-btn,
    .green-btn.green-outline-btn:hover {
        margin-left: 0;
        width: 100%;
        margin-top: 7px;
    }

    .login-form-box {
        padding: 21px 16px;
    }

    .login-body .col-md-6 .mb-4 {
        margin-bottom: 10px !important;
    }

    .cultivatorss-content .swiper-wrapper {
        padding-bottom: 42px;
    }

    .cultivator-content {
        padding: 12px 20px;
    }

    p,
    .join-us-now-btn,
    .login-body .form-label,
    .login-body p,
    .login-body p a {
        font-size: 14px;
    }

    .hero-left-content h1,
    .subheading {
        font-size: 20px;
    }

    .hero-left-content h3,
    .under-subheading {
        font-size: 16px;
        letter-spacing: 0;
        margin-top: 6px;
    }
    .date-time-section .inner-div {
        width: 100%;
        margin-bottom: 6px;
    }
    .dashboard-management.common-under-padding.status-bar.justify-content-between h2 {
        padding-right: 0;
    }

    .service-inner {
        padding: 19px;
    }

    .service-inner h2 {
        font-size: 35px;
    }

    .green-btn,
    .form-newslater .form-control {
        font-size: 14px;
    }

    header .navbar-light .navbar-nav .nav-link {
        font-size: 14px;
        font-weight: 600;
    }

    .under-heading {
        flex-direction: column;
    }

    .under-heading .green-btn,
    .under-heading .green-btn:hover {
        margin-top: 10px;
    }
    .common-tabs .nav-tabs .nav-link {
        margin-left: 2px;
    }
    .status-txt {
        margin-left: 0;
    }
}

@media (max-width: 420px) {
    .heading-top-area-right.date-range-filter, .date-time-section {
        flex-direction: column;
    }
    .pick-date{
        margin-right: 0;
    }
    button.apply-top-btn {
        width: 68%;
    }

}

@media (max-width: 380px) {
    .profile-upload .form-control {
        left: 30%;
    }
    .quantity-box .form-control {
        width: 32%;
    }
    .product-header-area {
        padding-bottom: 9px;
    }

    .preview-box-modal .modal-content {
        padding: 9px;
    }

    .green-btn.radius-btn,
    .green-btn.radius-btn.cancel-btn-outline {
        padding: 6px 31px;
    }

    .pagination-tab {
        padding: 6px 8px;
    }

    .form-control::file-selector-button {
        font-size: 12px;
    }

    .login-body .form-control,
    .login-body .form-select {
        font-size: 14px;
    }

    .action-btn .green-btn,
    .action-btn .green-btn:hover {
        width: 50%;
    }

    .under-heading {
        flex-direction: column;
    }

}

.otp-display {
    font-weight: bold;
    color: black;
    margin-left: 8px;
}


/* responsiveness end */